import React　from "react"
import {graphql} from "gatsby";
import Layout from "../../containers/Layout";
import Seo from "../../containers/Seo";
import * as css from "../../sass/contact.module.scss"

const ContactCompletePage = () => {
  return (
    <Layout>

      <section className={css.contact}>
        <h1>お問い合わせ</h1>

        <p>
          お問い合わせいただきありがとうございます。<br />
          担当者より返信いたしますので、しばらくお待ちくださいますようお願いいたします。
        </p>
      </section>

    </Layout>
  );
}

export default ContactCompletePage;

export const query = graphql`
  query ContactCompletePageQuery {
    site {
      siteMetadata {
        title
        description
        author
        twitter
        siteUrl
      }
    }
  }
`

export const Head = ({data}) => (
  <Seo data={data}>
    <title id="title">お問い合わせ完了 | {data.site.siteMetadata.title}</title>
  </Seo>
);
